<template>
    <nav class="navigation-secondaire" id="navigation-secondaire">
        <ul class="container nav-secondaire">
            <li v-for="(metier, index) in tabMetier" :key="metier.ID_METIER" class="item-nav">
                <a :id="index" @click="focusCurrent(index, metier.COD_MET)"
                    :class="{ 'link-metier': true, 'current': selectedMetier === index }" :title="metier.COM_MET"
                    ref="focusableElements">
                    <i class="icon" :class="metier.ICO_MET"></i>
                    <span>{{ metier.LIB_MET }}</span>
                </a>
            </li>
        </ul>

    </nav>
    <figure v-if="!isMobile" class=" banner-pl">
        <img src="../assets/jpg/banner-pl.jpg" alt="Magasin poid-lourds">
    </figure>
</template>
<script>
import Swal from 'sweetalert2';
export default {
    
    name: "navigationSecondaire",
    data() {
        return {
            tabMetier: [],
            selectedMetier: 0, // 0 index par defaut pour mettre le current dessus puis Pour suivre l'élément actuellement sélectionné
        };
    },
    created() {
        this.listeMetier();
    },
    mounted() {
        this.selectedMetier = Number(this.$route.query.index) || 0;
    },
    methods: {
        checkScreenSize() {
            const screenWidth = window.innerWidth;
            this.isMobile = screenWidth < 899;
        },
        listeMetier: function () {
            const instance = this.$sessionStorage.get("instance");
            const id_adherent = this.$sessionStorage.get("id_adherent");
            const prog = "./src/req/ListeMetier.php";
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    i_instance: instance.toUpperCase(),
                    i_id_adherent: id_adherent,
                }),
            };
            fetch(prog, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data.retour === "OK") {
                        this.tabMetier = data.tabMetier;
                    } else {
                        this.retourError = true;
                    }
                });
        },
        focusCurrent(index, cod_met) {
            // Retirer la classe 'current' de l'élément actif précédent
            const elementCurrent = document.querySelector('.current');
            if (elementCurrent) {
                document.querySelector('.current').classList.remove('current');
                document.body.classList.remove("open");
            };
            this.selectedMetier = index;
            this.$router.push({ name: "portail" + cod_met, query: { index: index } });
            // if (cod_met && cod_met != 'VL'){
            //     this.$swal.fire({
            //         icon: 'success',
            //         title: 'Ouverture prochaine.',
            //         showCloseButton: true,
            //         allowOutsideClick: false,
            //         }).then(() => {
            //     });
            //     // this.$router.push({ name: "portail" + cod_met, query: { index: index } });
            // } else {
            //     console.error('cod_met is undefined or null');
            // }
        },
    },
};
</script>
